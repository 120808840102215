<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  Create license key
                </h1>
                <div class="page-header-subtitle">Create a license key to get started with your scanning app.</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container-xl px-4 mt-n10" *ngIf="formGroup">
        <form *ngIf="account && account.hasSubscription; else noSubscription" [formGroup]="formGroup" (ngSubmit)="createLicenseKey()">
          <div class="card">
            <div class="card-header">Create license key</div>
            <div class="card-body">

              <p class="card-text">
                A license key is required to use our SDK.
                To create a license, please provide a comma-separated list of URLs where your app will be hosted.
              </p>
              <p>
                If your app has multiple environments (e.g. production, staging, qa, dev, etc.), you can either
                include all URLs in a single license key, or create per-environment license keys.
                localhost/127.0.0.1 and private IP ranges (192.168.x.y, 10.x.y.z) do not need to be added to the
                license key, they are automatically included.
              </p>

              <div *ngIf="errorMessage" class="alert alert-danger">
                An error occurred while attempting to create a new license key. If the problem persists, please
                contact support.

                <p class="text-sm mt-2 mb-0">
                  Error message: {{ errorMessage }}
                </p>
              </div>

              <div class="row gx-3">
                <div class="col">
                  <label class="small mb-1" for="scope">License key scope</label>
                  <input class="form-control" [class.is-invalid]="!formGroup.valid" id="scope" type="text"
                         placeholder="https://app.example.com, https://staging-app.example.com"
                         formControlName="scope"
                         autofocus
                  />
                  <ng-container *ngIf="scope && scope.invalid">
                    <div class="invalid-feedback" *ngIf="scope.errors?.['required']">
                      Please enter a comma-separated list of HTTPS URLs.
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['invalid_url']">
                      {{ scope.errors?.['invalid_url'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['insecure_url']">
                      {{ scope.errors?.['insecure_url'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['no_wildcards']">
                      {{ scope.errors?.['no_wildcards'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['single_wildcard']">
                      {{ scope.errors?.['single_wildcard'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['wildcard_first']">
                      {{ scope.errors?.['wildcard_first'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['redundant_localhost']">
                      {{ scope.errors?.['redundant_localhost'] }}
                    </div>
                    <div class="invalid-feedback" *ngIf="scope.errors?.['redundant_private_ip']">
                      {{ scope.errors?.['redundant_private_ip'] }}
                    </div>
                  </ng-container>
                  <div *ngIf="scope && scope.valid" class="valid-feedback d-block">
                    <p class="small">
                      {{ validatedUrls.length }} URLs in scope:
                      <ng-container *ngFor="let url of validatedUrls; let last = last;">
                        <b>{{ url }}</b><span *ngIf="!last">, </span>
                      </ng-container>
                    </p>
                  </div>

                  <div [class.d-none]="!account.hasAnyCustomCapability" class="mt-5">
                    <p>
                      In addition to the scope, you can set additional capabilities for your new license key. The available
                      capabilities depend on your account‘s subscription/negotiated contract.
                    </p>

                    <div class="form-check mt-1">
                      <input class="form-check-input" formControlName="analyticsOptOut" type="checkbox" value="" id="analyticsOptOut">
                      <label class="form-check-label" for="analyticsOptOut">
                        Opt-out of Analytics (Enterprise-only)
                      </label>
                    </div>

                    <div class="form-check mt-1">
                      <input class="form-check-input" formControlName="offline" type="checkbox" value="" id="offline">
                      <label class="form-check-label" for="offline">
                        Offline license check (Enterprise-only)
                      </label>
                    </div>

                    <div class="form-check mt-1">
                      <input class="form-check-input" formControlName="customOverlayLogo" type="checkbox" value="" id="customOverlayLogo">
                      <label class="form-check-label" for="customOverlayLogo">
                        Override or hide STRICH logo (Enterprise-only)
                      </label>
                    </div>
                  </div>

                  <div class="mt-3">
                    <button [disabled]="busy || !formGroup.valid" class="btn btn-primary btn-block" type="submit">Create License Key</button>
                  </div>
                </div>
              </div>


            </div> <!-- card-body -->
          </div>
        </form>

        <ng-template #noSubscription>
          <div class="card">
            <div class="card-header">Create license key</div>
            <div class="card-body">

              <p class="card-text">
                To create a license key, you need to create a <a routerLink="/dashboard">subscription</a> first.
              </p>
            </div>
          </div>
        </ng-template>
      </div>

    </main>
    <app-footer></app-footer>
  </div>
</div>
