<footer class="footer-admin mt-auto footer-light">
  <div class="container-xl px-4">
    <div class="row">
      <div class="col-md-6 small">Copyright &copy; 2023 – 2025 <a href="https://pixelverse.ch/">Pixelverse GmbH</a></div>
      <div class="col-md-6 text-md-end small">
        <a routerLink="/privacy-policy">Privacy Policy</a>
        &middot;
        <a href="https://strich.io/assets/legal/STRICH_Subscription_License_Agreement.pdf" target="_blank">License Agreement</a>
      </div>
    </div>
  </div>
</footer>
