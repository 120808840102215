<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main *ngIf="account">
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">License keys</h1>
                <div class="page-header-subtitle">Manage your SDK license keys.</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container-xl px-4">

        <div class="row">
          <div class="col mt-4">
            <div class="alert alert-info" *ngIf="infoMessage">
              {{ infoMessage }}
            </div>
            <div class="alert alert-danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </div>
          </div>

        </div>

        <div class="row mt-4" *ngIf="account.canCreateLicenseKey else cannotCreateLicenseKey">
          <div class="col">
            <div class="card">
              <div class="card-header">Create New License Key</div>
              <div class="card-body">
                <p class="card-text">
                  Create a new license key to integrate the SDK into your code.
                  License keys link your application to your STRICH SDK subscription.
                </p>
                <a routerLink="/create-license-key">
                  <button class="btn btn-primary mt-2">Create License Key</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <ng-template #cannotCreateLicenseKey>
          <div class="row mt-4">
            <div class="col">
              <div class="alert alert-info" role="alert">
                Your subscription is cancelled. You can not create new license keys.
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="licenseViewModels !== undefined && licenseViewModels.length > 0; else noLicenseKeysYet" class="row">
          <div *ngFor="let licenseViewModel of licenseViewModels" class="col-xl-6 mt-4">
            <div class="card">
              <div class="card-header">License Key</div>
              <div class="card-body">
                <p class="card-text">
                  ID: {{ licenseViewModel.licenseId }}<br>
                  <span class="text-sm text-muted">Created on: {{ licenseViewModel.creationDate }}</span><br>
                  <ng-container *ngIf="licenseViewModel.expirationDate">
                    <span class="text-sm text-muted">Expires on: {{ licenseViewModel.expirationDate }}</span>
                    &nbsp;
                    <span class="badge badge-sm bg-warning" *ngIf="licenseViewModel.expiresSoon">EXPIRES SOON</span>
                    <span class="badge badge-sm bg-danger" *ngIf="licenseViewModel.expired">EXPIRED</span>
                  </ng-container>
                </p>
                <div class="card-text">
                  Scope (App URLs):<br>
                  <ul class="list-group mt-2">
                    <li class="list-group-item" *ngFor="let scope of licenseViewModel.scope">
                      {{ scope }}
                    </li>
                  </ul>
                </div>
                <div class="license-capabilities mt-2">
                  <span class="badge bg-secondary" *ngIf="licenseViewModel.offline">Offline-capable</span>
                  <span class="badge bg-secondary" *ngIf="licenseViewModel.analyticsOptOut">Analytics opt-out</span>
                  <span class="badge bg-secondary" *ngIf="licenseViewModel.customOverlayLogo">Logo overridable</span>
                </div>
                <div class="mt-4">
                  <button class="btn btn-outline-primary" type="button" (click)="licenseViewModel.toggleLicenseKey()">
                    {{ licenseViewModel.toggleButtonText }}
                  </button>

                  <button class="btn btn-link link-danger" type="button" (click)="onDeleteLicense(licenseViewModel)">
                    Delete license
                  </button>
                </div>

                <div class="collapse mt-4" [class.show]="licenseViewModel.showLicenseKey">
                  <div class="card card-body">

                    <code>
                      {{ licenseViewModel.licenseKey }}
                    </code>

                    <p class="card-text mt-4 text-primary" *ngIf="!showCopiedHint">
                      <i-feather name="copy"></i-feather>
                      <a [routerLink]="" class="btn-link ms-2" (click)="copyKeyToClipboard(licenseViewModel.licenseKey)">
                        Copy to clipboard
                      </a>
                    </p>
                    <p class="card-text mt-2" *ngIf="showCopiedHint">
                      Copied to clipboard!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #noLicenseKeysYet>

          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header">No license keys</div>
                <div class="card-body">
                  <p class="card-text">
                    You don't have any license keys yet.
                  </p>

                  <p class="card-text" *ngIf="account.canCreateLicenseKey else noSubscription">
                    Create a new license key to integrate the SDK into your code.
                  </p>

                  <ng-template #noSubscription>
                    <p class="card-text">To create a license key, first
                      <a routerLink="/dashboard">create a subscription</a>.</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

        </ng-template>

      </div> <!-- container -->
    </main>
    <app-footer></app-footer>
  </div>
</div>

<!-- confirm license key deletion modal -->
<div id="deleteLicenseKeyModal" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirm license key deletion</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Do you really want to delete this license key?</p>
        <p>This can not be undone.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="confirmLicenseDeletion()">Delete</button>
      </div>
    </div>
  </div>
</div>
